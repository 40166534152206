<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!--  -->
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <DxDataGrid id="gvTemplates" ref="gvTemplates" v-bind="options" :data-source="templatesStore">
              <DxColumn data-field="name" caption="Название">
                <DxRequiredRule />
              </DxColumn>
              <DxColumn :width="225" data-field="templateGroupId" caption="Тип документа">
                <DxRequiredRule />
                <DxLookup :data-source="templateGroupsStore" display-expr="name" value-expr="id" />
              </DxColumn>
              <DxColumn :width="125" data-field="isArchive" caption="Архивный" data-type="boolean" />
              <DxColumn data-field="file" :allow-sorting="false" cell-template="cellFileTemplate"
                edit-cell-template="editCellFileUpload" caption="Документ">
                <DxRequiredRule />
              </DxColumn>

              <!-- Templates -->
              <template #cellFileTemplate="{ data }">
                <a href="#" @click.prevent="download(data.value)">{{ data.value.fileName }}</a>
              </template>
              <template #editCellFileUpload="{ data }">
                <div>
                  <DxFileUploader name="file" :multiple="false" upload-mode="instantly" :chunk-size="200000"
                    :upload-url="fileUploadUrl" :uploadHeaders="headers" @uploaded="(e) => onUploaded(e, data)"
                    @upload-error="onUploadError" />
                </div>
              </template>

              <DxEditing :allow-adding="true" :allow-updating="true" :allow-deleting="true" mode="form">
                <DxPopup height="auto" width="800" />
              </DxEditing>
              <DxFilterRow :visible="true" />
              <DxScrolling row-rendering-mode="virtual" />
              <DxPaging :page-size="20" />
              <DxPager :visible="true" :allowed-page-sizes="[5, 10, 20, 50]" :show-page-size-selector="true"
                :show-navigation-buttons="true" :show-info="true" />
            </DxDataGrid>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </Layout>
</template>

<script>
const fileUploadUrl = process.env.VUE_APP_URL + "/api/Files/UploadChunk";
const fileDownloadUrl = process.env.VUE_APP_URL + "/api/Files/";

import axios from "axios";
import { templateGroupsStore, documentTypesStore } from "@/helpers/common-store/store";
import { authHeader } from "@/helpers/authservice/auth-header";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";

import { DxPopup } from "devextreme-vue/data-grid";
import DxFileUploader from "devextreme-vue/file-uploader";

import createStoreExtend from "@/helpers/grid/store";
import settings from "@/helpers/grid/settings.js";

export default {
  components: {
    Layout,
    PageHeader,
    DxPopup,
    DxFileUploader,
  },

  data() {
    return {
      fileUploadUrl,
      fileDownloadUrl,
      templateGroupsStore,
      documentTypesStore,
      headers: authHeader(),
      title: "Печатные формы",
      items: [
        {
          text: "Справочник",
          href: "/",
        },
        {
          text: "Печатные формы",
          active: true,
        },
      ],

      options: settings,
      templatesStore: createStoreExtend({
        key: "fileId",
        loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_TEMPLATES_GET}`,
        insertUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_TEMPLATES_CREATE}`,
        updateUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_TEMPLATES_UPDATE}`,
        deleteUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_TEMPLATES_DELETE}`,
      }),
    };
  },
  methods: {
    download(item) {
      axios.get(fileDownloadUrl + item.id, { responseType: "arraybuffer" }).then(function (response) {
        var headers = response.headers;
        var blob = new Blob([response.data], { type: headers["content-type"] });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = item.fileName;
        link.click();
      });
    },
    onUploaded(e, cellInfo) {
      var obj = JSON.parse(e.request.responseText);
      var file = { id: 0, tempFileName: obj.tempFileName, fileName: e.file.name };
      cellInfo.setValue(file);
    },
    onUploadError(e) {
      let xhttp = e.request;
      if (xhttp.status === 400) {
        e.message = e.error.responseText;
      }
      if (xhttp.readyState === 4 && xhttp.status === 0) {
        e.message = "Connection refused";
      }
    },
  },
};
</script>

<style>
</style>
